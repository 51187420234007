import { render, staticRenderFns } from "./BlogOverviewCard.vue?vue&type=template&id=37d3c26e&"
import script from "./BlogOverviewCard.vue?vue&type=script&lang=js&"
export * from "./BlogOverviewCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppImage: require('/vercel/path0/components/general/AppImage.vue').default,ArrowLink: require('/vercel/path0/components/general/ArrowLink.vue').default})
