
export default {
  props: {
    permalink: {
      type: String,
      required: true,
    },
  },

  computed: {
    mailtoUrl() {
      return `mailto:?body=${encodeURIComponent(this.permalink)}`
    },

    facebookUrl() {
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.permalink
      )}`
    },

    twitterUrl() {
      return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        this.permalink
      )}`
    },

    linkedinUrl() {
      return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
        this.permalink
      )}`
    },

    whatsappUrl() {
      return `https://api.whatsapp.com/send?text=${encodeURIComponent(
        this.permalink
      )}`
    },
  },

  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.permalink)
    },
  },
}
