import { render, staticRenderFns } from "./_.vue?vue&type=template&id=45162dfc&"
import script from "./_.vue?vue&type=script&lang=js&"
export * from "./_.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlueprintBlogOverview: require('/vercel/path0/components/blueprints/BlueprintBlogOverview.vue').default,BlueprintBlogArticle: require('/vercel/path0/components/blueprints/BlueprintBlogArticle.vue').default,CustomText: require('/vercel/path0/components/content/CustomText.vue').default,Container: require('/vercel/path0/components/general/Container.vue').default,HeroLoader: require('/vercel/path0/components/general/HeroLoader.vue').default,ComponentLoader: require('/vercel/path0/components/general/ComponentLoader.vue').default})
