
export default {
  async asyncData({ route, $axios, error }) {
    let path = route.path
    if (route.path === '/home') {
      path = '/'
    }
    let data = {}
    try {
      data = await $axios.$get(`/pages${path}`)
    } catch (err) {
      error({
        statusCode: err.response?.status,
        message: err.response.data?.message,
      })
    }
    return data
  },

  head() {
    const head = {
      meta: [
        {
          property: 'og:url',
          content: process.env.baseUrl + this.data.url,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:image',
          content: this.pageImage().permalink,
        },
        {
          property: 'og:image:alt',
          hid: 'og:image:alt',
          content: this.pageImage().alt,
        },
      ],

      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: process.env.baseUrl + this.data.url,
        },
        {
          rel: 'alternate',
          hreflang: 'x-default',
          href: process.env.baseUrl + this.data.url,
        },
      ],
    }

    head.meta.push({
      name: 'twitter:card',
      content: 'summary',
    })

    if (this.data.seo_hidden) {
      head.meta.push({ hid: 'robots', name: 'robots', content: 'noindex' })
    }

    // TITLE
    if (this.data.seo_title || this.data.title) {
      head.meta.push({
        hid: 'title',
        name: 'title',
        content: this.data.seo_title || this.data.title,
      })
      head.meta.push({
        property: 'og:title',
        content: this.data.seo_title || this.data.title,
      })
    }

    // DESCRIPTION
    if (this.data.seo_description || this.data.description) {
      head.meta.push({
        hid: 'description',
        name: 'description',
        content: this.data.seo_description || this.data.description,
      })
      head.meta.push({
        property: 'og:description',
        content: this.data.seo_description || this.data.description,
      })
    }

    return head
  },

  methods: {
    pageImage() {
      const empty = {
        permalink: undefined,
        alt: undefined,
      }
      if (!Array.isArray(this.data.heros) || !this.data.heros.length) {
        return empty
      }
      // if HomeHero
      if (this.data.heros[0].type === 'home_hero') {
        return {
          permalink: `${process.env.baseUrl}/headerImage.png`,
          alt: 'Altramex Nachhaltiger Boden Header Bild',
        }
      }
      // Hero with one image
      let image = {}
      if (this.data.heros[0].image) {
        image = this.data.heros[0].image
      }
      // Hero with two images
      if (this.data.heros[0].images) {
        image = this.data.heros[0].images[0]
      }
      // Hero with icon
      if (this.data.heros[0].icon) {
        return empty
      }
      if (this.data.image) {
        return this.data.image
      }
      return image
    },
  },
}
