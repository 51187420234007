
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    prevComponent: { type: String, default: null },
    nextComponent: { type: String, default: null },
  },
}
