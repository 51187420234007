
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },

  computed: {
    dateFormatted() {
      if (!this.data.date) {
        return ''
      }
      return new Date(this.data.date).toLocaleDateString('de', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
      })
    },
  },
}
