import { render, staticRenderFns } from "./BlueprintBlogArticle.vue?vue&type=template&id=2f53c400&"
import script from "./BlueprintBlogArticle.vue?vue&type=script&lang=js&"
export * from "./BlueprintBlogArticle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroBlogArticle: require('/vercel/path0/components/heros/HeroBlogArticle.vue').default,ComponentLoader: require('/vercel/path0/components/general/ComponentLoader.vue').default,SocialShareIcons: require('/vercel/path0/components/general/SocialShareIcons.vue').default})
