
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showAmount: 8,
    }
  },

  computed: {
    mainBogArticle() {
      if (
        !Array.isArray(this.data?.blog_overview_components) ||
        this.data?.blog_overview_components.length === 0
      ) {
        return []
      }
      return this.data.blog_overview_components[0].main_blog_article
    },

    totalAmountBlogArticles() {
      if (
        !Array.isArray(this.data?.blog_overview_components) ||
        this.data?.blog_overview_components.length === 0
      ) {
        return 0
      }
      return this.data.blog_overview_components[0].blog_articles.length
    },

    blogArticles() {
      if (
        !Array.isArray(this.data?.blog_overview_components) ||
        this.data?.blog_overview_components.length === 0
      ) {
        return []
      }

      return (
        this.data.blog_overview_components[0].blog_articles.slice(
          0,
          this.showAmount
        ) || []
      )
    },
  },
}
