import { render, staticRenderFns } from "./HeroBlogOverview.vue?vue&type=template&id=2d8a2ad6&"
import script from "./HeroBlogOverview.vue?vue&type=script&lang=js&"
export * from "./HeroBlogOverview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppImage: require('/vercel/path0/components/general/AppImage.vue').default,Header: require('/vercel/path0/components/navigation/Header.vue').default})
