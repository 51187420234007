import { render, staticRenderFns } from "./SocialShareIcons.vue?vue&type=template&id=96b23e56&"
import script from "./SocialShareIcons.vue?vue&type=script&lang=js&"
export * from "./SocialShareIcons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MailIcon: require('/vercel/path0/components/svg/MailIcon.vue').default,FacebookIcon: require('/vercel/path0/components/svg/FacebookIcon.vue').default,TwitterIcon: require('/vercel/path0/components/svg/TwitterIcon.vue').default,LinkedinIcon: require('/vercel/path0/components/svg/LinkedinIcon.vue').default,WhatsappIcon: require('/vercel/path0/components/svg/WhatsappIcon.vue').default,CopyIcon: require('/vercel/path0/components/svg/CopyIcon.vue').default})
